import React from "react";
import { Col, Divider, Row } from "antd";
import { AppContextFilesPlusRollupFeed } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import EcObjectCardsContainer from "../../ecObjectViewers/ecObjectCardsContainer";
import { convertWeekStringToHumanReadable } from "../../../utils/dayJsUtils";
import ArtifactCardWithCoverImage from "../../common/generic/artifactCardWithCoverImage";

const FilesPlusRollupInner = ({ listQueryData, orderBy, cardProperties }) => {
  const getLoadingSkeleton = () => {
    return (
      <div>
        <div style={{ marginBottom: 30 }}>
          <h2>Loading...</h2>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <ArtifactCardWithCoverImage />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <ArtifactCardWithCoverImage />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <ArtifactCardWithCoverImage />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <>
      {listQueryData
        ? listQueryData?.weeks?.map((week, index) => {
            const humanReadableDate = convertWeekStringToHumanReadable(week);
            let foundInMessagesText = `Found in messages sent during the week of ${humanReadableDate}`;
            if (orderBy === "submission_date") {
              foundInMessagesText = `Found in messages submitted during the week of ${humanReadableDate}`;
            }
            if (Object.keys(listQueryData.items_by_week).length > 0) {
              return (
                <div key={index}>
                  <div style={{ marginBottom: 30 }}>
                    <h2>{foundInMessagesText}</h2>
                    <EcObjectCardsContainer
                      ecObjects={listQueryData.items_by_week[week]}
                      appContext={AppContextFilesPlusRollupFeed}
                      cardProperties={cardProperties}
                      showClickDetailsTag={true}
                      scrollToFirstHighlight={false}
                    />
                  </div>
                  <Divider />
                </div>
              );
            } else {
              return <span key={index} />;
            }
          })
        : getLoadingSkeleton()}
    </>
  );
};

export default FilesPlusRollupInner;
