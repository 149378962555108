import React from "react";
import { ALL_CLASSES_BY_CLASS_ID } from "../actionMappers/classesToActions";
import ObjectCardActionsDropdown from "../cardParts/objectCardActionsDropdown";
import TagForMessagesContainingArtifact from "../cardParts/tagForMessagesContainingArtifact";
import { AppContextPublicBoard } from "../actionMappers/appContextsToActions";
import { Typography } from "antd";
import AnchorNoPropagation from "../../common/generic/anchorNoPropagation";

const AnyCard = ({
  ecObject,
  appContext,
  onClickAction,
  savedObject,
  cardProperties,
  onClickViewEcObjectDetails,
}) => {
  const getDetailsLinkForMessageAndSummaryBullet = (onClick) => {
    return (
      <Typography.Paragraph ellipsis={{ rows: 2 }}>
        <Typography.Text strong={true}>
          <AnchorNoPropagation onClick={onClick}>Details</AnchorNoPropagation>
        </Typography.Text>
      </Typography.Paragraph>
    );
  };

  const getCard = () => {
    if (!ecObject) {
      return <></>;
    }

    const CardClass = ALL_CLASSES_BY_CLASS_ID[ecObject.class_id].cardClass;

    const getSourceMessageInfo = () => {
      const onClick = (obj, message) => {
        onClickViewEcObjectDetails(obj, message, savedObject);
      };

      if (appContext === AppContextPublicBoard) {
        return <></>;
      } else if (ecObject.class_id === "message") {
        return getDetailsLinkForMessageAndSummaryBullet(onClick);
      } else if (ecObject.class_id === "summary_bullet") {
        return getDetailsLinkForMessageAndSummaryBullet(onClick);
      } else {
        return (
          <TagForMessagesContainingArtifact
            artifactObject={ecObject}
            onClick={onClick}
          />
        );
      }
    };

    return (
      <CardClass
        ecObject={ecObject}
        cardProperties={cardProperties}
        onClickViewEcObjectDetails={(obj, message) => {
          onClickViewEcObjectDetails(obj, message, savedObject);
        }}
        sourceMessageInfo={getSourceMessageInfo()}
        actionMenu={
          <ObjectCardActionsDropdown
            ecObject={ecObject}
            appContext={appContext}
            onClick={onClickAction}
            savedObject={savedObject}
            cardProperties={cardProperties}
          />
        }
      />
    );
  };

  return <>{getCard()}</>;
};

export default AnyCard;
