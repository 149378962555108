import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { O_SERVICE_EC_PROXY_GMAIL_LABELS_LIST } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";

const useListGmailLabels = (enabled) => {
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_EC_PROXY_GMAIL_LABELS_LIST,
    true,
    [],
    {},
    enabled,
  );
};

export default useListGmailLabels;
