import React from "react";
import { Select } from "antd";
import useListGmailLabels from "./hooks/useListGmailLabels";
import useGetGoogleAndGmailConnectionInfo from "./hooks/useGetGoogleAndGmailConnectionInfo";

const UNASSIGNED_ID_VALUE = "UNASSIGNED_ID_VALUE";

const GmailLabelSelect = ({ value, onChange }) => {
  const { isConnected } = useGetGoogleAndGmailConnectionInfo();
  const { data: gmailLabels, isLoading: isLoadingGmailLabels } =
    useListGmailLabels(isConnected);

  const getOptions = () => {
    if (gmailLabels) {
      const options = gmailLabels.map((gmailLabel) => {
        return {
          value: gmailLabel.id,
          label: gmailLabel.name,
        };
      });
      options.unshift({ value: UNASSIGNED_ID_VALUE, label: " " });
      return options;
    } else {
      return [];
    }
  };

  const getValue = () => {
    if (gmailLabels) {
      for (const i in gmailLabels) {
        if (gmailLabels[i].name === value) {
          return { value: gmailLabels[i].id };
        }
      }
    }
  };

  return (
    isConnected && (
      <>
        <div style={{ marginTop: 20 }}>Gmail label for syncing</div>
        <Select
          labelInValue
          loading={isLoadingGmailLabels}
          style={{
            width: 240,
          }}
          value={getValue()}
          onChange={(selectedItem) => onChange(selectedItem.label)}
          options={getOptions()}
        />
      </>
    )
  );
};

export default GmailLabelSelect;
