import React, { useEffect } from "react";
import HomeEventsCard from "./parts/homeEventsCard";
import HomeFilesCard from "./parts/homeFilesCard";
import { Divider } from "antd";

const HomeDiscover = ({ handleOpenArtifactOverlay }) => {
  useEffect(() => {
    document.title = "Dashboard | TLDR Parents";
  }, []);

  const getJsx = () => {
    return (
      <>
        <HomeEventsCard onClickItem={handleOpenArtifactOverlay} />
        <Divider />
        <HomeFilesCard />
      </>
    );
  };

  return getJsx();
};

export default HomeDiscover;
