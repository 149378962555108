import GmailAuth0ProviderContext from "../../context/gmailAuth0ProviderContext";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";

const authorizationParamsMain = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirect_uri: `${window.location.origin}?primary`,
};

// if (process.env.REACT_APP_ENVIRONMENT === "production") {
//   authorizationParamsMain.scope = "openid profile";
//   authorizationParamsMain.connection = "google-oauth2";
// }

export const auth0UniversalProviderConfig = {
  context: Auth0UniversalProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: authorizationParamsMain,
  skipRedirectCallback: window.location.href.includes("?gmail"),
};

export const gmailProviderConfig = {
  context: GmailAuth0ProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    // audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: `${window.location.origin}?gmail`,
    scope: "openid profile",
    connection: "google-oauth2",
    connection_scope: "https://www.googleapis.com/auth/gmail.readonly",
    access_type: "offline",
    upstream_params: {
      prompt: "select_account consent",
      access_type: "offline",
      // prompt: "select_account",
    },
    // approval_prompt: "force",
  },
  skipRedirectCallback: window.location.href.includes("?primary"),
};
