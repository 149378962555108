import React, { useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Divider,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import useMessageHeaderOutlet from "./hooks/useMessageHeaderOutlet";
import useMessageCatalog from "./hooks/useMessageCatalog";
import { Outlet } from "react-router-dom";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import useUpdateMessages from "../../../hooks/emailCollector/messages/useUpdateMessages";
import {
  DeleteOutlined,
  InboxOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import ObjectCardActionsDropdown from "../../ecObjectViewers/cardParts/objectCardActionsDropdown";
import { AppContextViewMessage } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import useCreateOrEditOrchestrateObject from "../../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import OrchestrateObjectFormOverlays from "../../familyOrganizer/orchestrateObjectFormOverlays";
import MessageMetadata from "./messageMetadata";
import HelpIconAndModal from "../../helpIconAndModal";
import SmartInboxEducationModal from "../inbox/smartInboxEducationModal";

const { Text } = Typography;

const MessageHeaderOutlet = ({ archived }) => {
  const {
    familyId,
    setBreadcrumbs,
    setBreadcrumbExtra,
    clearBreadcrumbExtra,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
  } = useTldrPageContext();

  const {
    currentUniqueMessageId,
    totalCount,
    position,
    messageIds,
    hasNext,
    hasPrev,
    handleClickPrev,
    handleClickNext,
  } = useMessageHeaderOutlet(archived);

  const { getMessageByUniqueMessageId } = useMessageCatalog(messageIds);

  const currentMessageObject = getMessageByUniqueMessageId(
    currentUniqueMessageId,
  );

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    onClickCardAction,
  } = useCreateOrEditOrchestrateObject();

  useEffect(() => {
    if (archived) {
      setBreadcrumbs([
        {
          link: `/families/${familyId}/archived-messages`,
          label: "Archived Messages",
        },
        { label: "Message" },
      ]);
      clearBreadcrumbExtra();
    } else {
      setBreadcrumbs([
        {
          link: `/families/${familyId}/smart-inbox`,
          label: "Smart Inbox",
        },
        { label: "Message" },
      ]);
      setBreadcrumbExtra(
        <HelpIconAndModal educationModalClass={SmartInboxEducationModal} />,
      );
    }
  }, [
    familyId,
    archived,
    setBreadcrumbs,
    setBreadcrumbExtra,
    clearBreadcrumbExtra,
  ]);

  const { mutate: archiveMessage } = useUpdateMessages(
    () => {
      openLoadingMessage("updateMessageArchive", "Archiving message");
    },
    () => {
      openSuccessMessage("updateMessageArchive", "Done archiving message");
    },
    () => {
      openErrorMessage("updateMessageArchive", "Error archiving message");
    },
    false,
  );

  const { mutate: moveMessageToInbox } = useUpdateMessages(
    () => {
      openLoadingMessage(
        "updateMessageArchive",
        "Moving message to Smart Inbox",
      );
    },
    () => {
      openSuccessMessage(
        "updateMessageArchive",
        "Done moving message to Smart Inbox",
      );
    },
    () => {
      openErrorMessage(
        "updateMessageArchive",
        "Error moving message to Smart Inbox",
      );
    },
    false,
  );

  const getPrevNextButtonComponent = () => {
    if (currentMessageObject) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {position} of {totalCount}
            </div>
            <Tooltip title="Newer" placement="bottom">
              <Button
                size={"small"}
                style={{ width: 50, marginRight: 6, marginLeft: 6 }}
                disabled={!hasPrev}
                onClick={() => {
                  handleClickPrev();
                }}
                icon={<LeftOutlined />}
              />
            </Tooltip>
            <Tooltip title="Older" placement="bottom">
              <Button
                size={"small"}
                style={{ width: 50 }}
                disabled={!hasNext}
                onClick={() => {
                  handleClickNext();
                }}
                icon={<RightOutlined />}
              />
            </Tooltip>
          </div>
        </div>
      );
    }
  };

  const getInfoRow = () => {
    if (!currentMessageObject) return <></>;
    return (
      <>
        <Row>
          <Col>
            <h2>
              {!currentMessageObject.subject ||
              currentMessageObject.subject === ""
                ? "- No Subject -"
                : currentMessageObject.subject}
            </h2>
            <Text type="secondary">
              <MessageMetadata messageObject={currentMessageObject} />
            </Text>
            {currentMessageObject?.status !== "complete" && (
              <Alert
                type="info"
                style={{ marginTop: 5 }}
                message={"We're still processing this message."}
              />
            )}
          </Col>
        </Row>
        <Row
          justify="space-between"
          align={"middle"}
          style={{
            marginTop: 10,
            padding: "7px 0",
            borderRadius: 8,
          }}
        >
          <Col>
            <ObjectCardActionsDropdown
              ecObject={currentMessageObject}
              appContext={AppContextViewMessage}
              onClick={onClickCardAction}
              savedObject={null}
              cardProperties={null}
            />
          </Col>
          <Col>
            <Space split={<Divider type="vertical" />}>
              <Text type="secondary">{getPrevNextButtonComponent()}</Text>
              {archived ? (
                <Button
                  type={"primary"}
                  ghost={true}
                  icon={<InboxOutlined />}
                  onClick={() => {
                    moveMessageToInbox({
                      unique_message_ids: [currentUniqueMessageId],
                      updates: { archive_status: 0 },
                    });
                  }}
                />
              ) : (
                <Button
                  danger={true}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    archiveMessage({
                      unique_message_ids: [currentUniqueMessageId],
                      updates: { archive_status: 1 },
                    });
                  }}
                />
              )}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col flex="auto"></Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <OrchestrateObjectFormOverlays
        eventInitialFormValues={eventFormValues}
        eventMergeValues={eventMergeValues}
        eventOverlayOpen={eventOverlayOpen}
        onCancelEvent={() => closeEventOverlay()}
        onSubmitEvent={() => closeEventOverlay()}
        onPersistEvent={(item) => {}}
        todoInitialFormValues={todoFormValues}
        todoMergeValues={todoMergeValues}
        todoOverlayOpen={todoOverlayOpen}
        onCancelTodo={() => closeTodoOverlay()}
        onSubmitTodo={() => closeTodoOverlay()}
        onPersistTodo={(item) => {}}
        clippingInitialFormValues={clippingFormValues}
        clippingMergeValues={clippingMergeValues}
        clippingOverlayOpen={clippingOverlayOpen}
        onCancelClipping={() => closeClippingOverlay()}
        onSubmitClipping={() => closeClippingOverlay()}
        onPersistClipping={(item) => {}}
        tileInitialFormValues={tileFormValues}
        tileMergeValues={tileMergeValues}
        tileOverlayOpen={tileOverlayOpen}
        onCancelTile={() => closeTileOverlay()}
        onSubmitTile={() => closeTileOverlay()}
        onPersistTile={(item) => {}}
      />
      {getInfoRow()}
      <Outlet
        context={{
          messageObject: [currentMessageObject],
        }}
      />
      <Divider />
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Text type="secondary">{getPrevNextButtonComponent()}</Text>
      </div>
    </>
  );
};

export default MessageHeaderOutlet;
