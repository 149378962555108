import React, { useEffect, useState } from "react";
import { Button, Col, Row, Space, Tag, Typography } from "antd";
import FormattedDateTimeRange from "../../common/generic/formattedDateTimeRange";
import TagForMessagesContainingEvent from "./tagForMessagesContainingEvent";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import useUpdateTldrEventRollupHiddenStatus from "../hooks/useUpdateTldrEventRollupHiddenStatus";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  getDayJsDatetimeFromStringsGivenTimezones,
  getShortHumanizedDateTimeForEmail,
} from "../../../utils/dayJsUtils";
import IconAndText from "../../common/generic/iconAndText";
import ObjectCardActionsDropdown from "../../ecObjectViewers/cardParts/objectCardActionsDropdown";
import { AppContextMagicCalendar } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import { getOpenGoogleCalendarToDateUrl } from "../../../utils/utils";
import SavedObjectUsages from "./savedObjectUsages";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

const { Text } = Typography;

const MagicEventDetails = ({
  eventObject,
  savedObjectUsages,
  onClickFoundInMessages,
  showHideButton,
  onClickAddToGoogleCalendar,
  onClickCardAction,
  onUpdateHiddenStatus,
}) => {
  const {
    currentFamily,
    invalidateQueryWhereKeyContains,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
  } = useTldrPageContext();

  const startDate = getDayJsDatetimeFromStringsGivenTimezones(
    eventObject?.start_date,
    eventObject?.start_time,
    "UTC",
    currentFamily?.timezone || "UTC",
  );
  const urlToGoogleCalendar = getOpenGoogleCalendarToDateUrl(
    startDate.format("YYYY-MM-DD"),
  );

  const [isHiddenAtStart, setIsHiddenAtStart] = useState(eventObject.is_hidden);
  const [showAddedToGoogleCalendar, setShowAddedToGoogleCalendar] =
    useState(false);

  useEffect(() => {
    if (eventObject) {
      setIsHiddenAtStart(eventObject.is_hidden);
    }
  }, [eventObject]);

  useEffect(() => {
    let showAddedToGoogleCalendarLocal = false;
    if (savedObjectUsages) {
      for (const i in savedObjectUsages) {
        const relatedSavedObject = savedObjectUsages[i];
        if (relatedSavedObject.class_id === "google_event") {
          showAddedToGoogleCalendarLocal = true;
        }
      }
    }
    setShowAddedToGoogleCalendar(showAddedToGoogleCalendarLocal);
  }, [savedObjectUsages]);

  const { mutate: updateHiddenStatus } = useUpdateTldrEventRollupHiddenStatus(
    (item) => {
      if (onUpdateHiddenStatus) {
        onUpdateHiddenStatus(item);
      }
      const text = isHiddenAtStart ? "Restoring event" : "Dismissing event";
      openLoadingMessage("updatingEvent", text);
    },
    () => {
      const text = isHiddenAtStart ? "Event restored" : "Event dismissed";
      openSuccessMessage("updatingEvent", text);
      // invalidateQueryWhereKeyContains("events");
    },
    () => {
      const text = isHiddenAtStart
        ? "Error restoring event"
        : "Error dismissing event";
      openErrorMessage("updatingEvent", text);
    },
  );

  const getHiddenStatusButton = () => {
    let updateToValue = true;
    let buttonText = "Dismiss";
    if (eventObject.is_hidden) {
      updateToValue = false;
      buttonText = "Restore";
    }
    return (
      <Button
        onClick={() => {
          updateHiddenStatus({
            tenant_id: eventObject.tenant_id,
            event_rollup_sort_key: eventObject.event_rollup_sort_key,
            is_hidden: updateToValue,
          });
        }}
        type="primary"
        ghost
      >
        {buttonText}
      </Button>
    );
  };

  const getAddEventButton = () => {
    return (
      <>
        {/*{showAddedToGoogleCalendar ? (*/}
        {/*  <Button*/}
        {/*    onClick={() => {*/}
        {/*      window.open(urlToGoogleCalendar, "_blank");*/}
        {/*    }}*/}
        {/*    type="primary"*/}
        {/*    ghost={true}*/}
        {/*  >*/}
        {/*    Open Google Calendar*/}
        {/*  </Button>*/}
        {/*) : (*/}
        {/*  <Button*/}
        {/*    onClick={() => {*/}
        {/*      onClickAddToGoogleCalendar(eventObject);*/}
        {/*    }}*/}
        {/*    type="primary"*/}
        {/*  >*/}
        {/*    Add to calendar*/}
        {/*  </Button>*/}
        {/*)}*/}
      </>
    );
  };

  const showNew = () => {
    const age = (Date.now() - eventObject.created_ts) / 1000 / 60 / 60;
    return age < 60;
  };

  const GRADE_LEVEL_MAP = {
    pre: "pre-k",
    k: "kindergarten",
    1: "1st",
    2: "2nd",
    3: "3rd",
    4: "4th",
    5: "5th",
    6: "6th",
    7: "7th",
    8: "8th",
    9: "9th",
    10: "10th",
    11: "11th",
    12: "12th",
    college: "college",
  };

  const getItem = () => {
    return (
      <div>
        <h3>
          {eventObject.title}
          {"  "}
          <span style={{ fontSize: 25 }}>{eventObject.emoji}</span>
        </h3>
        <Space direction="vertical">
          {showNew() && (
            <Row wrap={false} align="middle">
              <Col>
                <span style={{ color: "#ff4081" }}>New!</span>{" "}
                <Text type="secondary">
                  {getShortHumanizedDateTimeForEmail(eventObject.created_ts)}
                </Text>
              </Col>
            </Row>
          )}

          {eventObject?.description && (
            <IconAndText
              icon={
                <DescriptionOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />
              }
              text={<Text type="secondary">{eventObject.description}</Text>}
            />
          )}
          <IconAndText
            icon={<EventOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />}
            text={
              <Text type="secondary">
                <FormattedDateTimeRange
                  startDateAsString={eventObject.start_date}
                  startTimeAsString={eventObject.start_time}
                  endDateAsString={eventObject.end_date}
                  endTimeAsString={eventObject.end_time}
                  timezoneInterpret={"UTC"}
                  timezoneDisplay={currentFamily?.timezone || "UTC"}
                />
              </Text>
            }
          />
          {eventObject?.location && (
            <IconAndText
              icon={
                <LocationOnOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />
              }
              text={<Text type="secondary">{eventObject.location}</Text>}
            />
          )}

          {eventObject?.grade_levels && eventObject.grade_levels.length > 0 && (
            <IconAndText
              icon={<SchoolOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />}
              text={
                <Space>
                  {eventObject.grade_levels.map((gradeLevel, index) => {
                    return <Tag key={index}>{GRADE_LEVEL_MAP[gradeLevel]}</Tag>;
                  })}
                </Space>
              }
            />
          )}
          <TagForMessagesContainingEvent
            eventObject={eventObject}
            onClick={(event, messages) =>
              onClickFoundInMessages(event, messages)
            }
          />
        </Space>
        <div style={{ marginTop: 10 }}>
          <Space>
            <ObjectCardActionsDropdown
              ecObject={eventObject}
              appContext={AppContextMagicCalendar}
              onClick={onClickCardAction}
              savedObject={null}
            />
            {getAddEventButton()}
            {showHideButton && getHiddenStatusButton()}
          </Space>
        </div>
        {savedObjectUsages?.length > 0 && (
          <div style={{ marginTop: 10 }}>
            <SavedObjectUsages savedObjectUsages={savedObjectUsages} />
          </div>
        )}
      </div>
    );
  };

  return <>{getItem()}</>;
};

export default MagicEventDetails;
