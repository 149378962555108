import React from "react";
import useListQueryRollup from "../../artifactRollups/hooks/useListQueryRollup";
import FilesPlusRollupInner from "../../artifactRollups/filesPlus/filesPlusRollupInner";
import { Space } from "antd";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import { useNavigate } from "react-router-dom";

const HomeFilesCard = () => {
  const { familyId } = useTldrPageContext();
  const navigate = useNavigate();

  const { data: filesPlusData } = useListQueryRollup(
    "tldr",
    null,
    36,
    0,
    "message_date",
    false,
  );

  return (
    <>
      <Space>
        <h1>Link Gallery Preview</h1>
        <ArrowForwardIcon
          sx={{ fontSize: 24, color: "#168dff", cursor: "pointer" }}
          onClick={() => navigate(`/families/${familyId}/key-links-files`)}
        />
      </Space>
      <FilesPlusRollupInner
        listQueryData={filesPlusData}
        orderBy={"message_date"}
        cardProperties={{
          link_image_rollup: {
            showLink: true,
            showImage: false,
          },
        }}
      />
    </>
  );
};

export default HomeFilesCard;
