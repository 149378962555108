import { useEffect, useState } from "react";
import useGetGoogleAuthTokenInfo from "./useGetGoogleAuthTokenInfo";
import useGetGoogleAuthTokenInfoWithForce from "./useGetGoogleAuthTokenInfoWithForce";

const useGetGoogleAndGmailConnectionInfo = () => {
  const [isForceRequestEnabled, setIsForceRequestEnabled] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(undefined);
  const [googleAuthTokenInfo, setGoogleAuthTokenInfo] = useState(undefined);

  const { data: googleAuthTokenInfoFromHook } =
    useGetGoogleAuthTokenInfo(false);

  const {
    data: googleAuthTokenInfoFromForceHook,
    refetch: refetchGoogleAuthTokenInfoWithForce,
  } = useGetGoogleAuthTokenInfoWithForce(isForceRequestEnabled, false);

  useEffect(() => {
    if (!isDone) {
      if (googleAuthTokenInfoFromHook) {
        if (
          googleAuthTokenInfoFromHook.access_type === "offline" &&
          googleAuthTokenInfoFromHook.has_gmail_read_only_scope &&
          googleAuthTokenInfoFromHook.has_refresh_token
        ) {
          setIsConnected(true);
          setGoogleAuthTokenInfo(googleAuthTokenInfoFromHook);
          setIsLoading(false);
          setIsDone(true);
          setIsForceRequestEnabled(false);
        } else {
          setIsForceRequestEnabled(true);
        }
      }
    }
  }, [googleAuthTokenInfoFromHook, isDone]);

  useEffect(() => {
    if (googleAuthTokenInfoFromForceHook) {
      if (
        googleAuthTokenInfoFromForceHook.access_type === "offline" &&
        googleAuthTokenInfoFromForceHook.has_gmail_read_only_scope &&
        googleAuthTokenInfoFromForceHook.has_refresh_token
      ) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
      setGoogleAuthTokenInfo(googleAuthTokenInfoFromForceHook);
      setIsLoading(false);
      setIsDone(true);
      setIsForceRequestEnabled(false);
    }
  }, [googleAuthTokenInfoFromForceHook]);

  const refetchAfterUserAction = async () => {
    await refetchGoogleAuthTokenInfoWithForce();
  };

  return {
    isLoading: isLoading,
    isConnected: isConnected,
    googleAuthTokenInfo: googleAuthTokenInfo,
    refetchAfterUserAction: refetchAfterUserAction,
  };
};

export default useGetGoogleAndGmailConnectionInfo;
