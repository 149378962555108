import React from "react";
import AnchorNoPropagation from "../../../../common/generic/anchorNoPropagation";
import { Skeleton, Table, Tag, Typography } from "antd";
import { AppContextSummaryBulletsMessage } from "../../../../ecObjectViewers/actionMappers/appContextsToActions";
import ObjectCardActionsDropdown from "../../../../ecObjectViewers/cardParts/objectCardActionsDropdown";
import useScreenSizeAndOrientation, {
  SCREEN_SIZE_EXTRA_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_SMALL,
} from "../../../../../hooks/utils/useScreenSizeAndOrientation";

const GRADE_LEVEL_ORDER = [
  "pre",
  "k",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "college",
];

const GRADE_LEVEL_MAP = {
  pre: "pre-k",
  k: "kindergarten",
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
  7: "7th",
  8: "8th",
  9: "9th",
  10: "10th",
  11: "11th",
  12: "12th",
  college: "college",
};

const getGradeOrderKey = (gradeLevel) => GRADE_LEVEL_ORDER.indexOf(gradeLevel);

const MessageSummaryBullets = ({
  messageArtifacts,
  onClickViewInMessage,
  onClickCardAction,
}) => {
  const { size: screenSize } = useScreenSizeAndOrientation();

  const getJsx = () => {
    if (!messageArtifacts) {
      return <Skeleton active={true} />;
    }

    if (!messageArtifacts?.summary_bullets?.length > 0) {
      return <></>;
    }

    const tableColumns = [
      {
        title: "Topic",
        key: "topic",
        ellipsis: true,
        render: (record) => (
          <>
            <Typography.Text strong={true}>
              <AnchorNoPropagation
                onClick={() => {
                  onClickViewInMessage(record);
                }}
              >
                {record.emoji} {record.title}
              </AnchorNoPropagation>
            </Typography.Text>
            {record.has_related_events === true && (
              <>
                <br />
                <Typography.Text type={"secondary"} italic={true}>
                  This Topic may be related to an Event Suggestion
                </Typography.Text>
              </>
            )}
          </>
        ),
      },
      {
        title: "Grade Levels",
        key: "grade_levels",
        hidden: [
          SCREEN_SIZE_EXTRA_SMALL,
          SCREEN_SIZE_SMALL,
          SCREEN_SIZE_MEDIUM,
        ].includes(screenSize),
        render: (record) => {
          const sortedGradeLevels = record.grade_levels.sort(
            (a, b) => getGradeOrderKey(a) - getGradeOrderKey(b),
          );
          return (
            <>
              {sortedGradeLevels.map((gradeLevel, index) => {
                return <Tag key={index}>{GRADE_LEVEL_MAP[gradeLevel]}</Tag>;
              })}
            </>
          );
        },
      },
      {
        title: "Categories",
        key: "categories",
        hidden: [
          SCREEN_SIZE_EXTRA_SMALL,
          SCREEN_SIZE_SMALL,
          SCREEN_SIZE_MEDIUM,
        ].includes(screenSize),
        render: (record) => {
          return (
            <>
              {record.categories.map((category, index) => {
                return <Tag key={index}>{category}</Tag>;
              })}
            </>
          );
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (record) => {
          return (
            <ObjectCardActionsDropdown
              ecObject={record}
              appContext={AppContextSummaryBulletsMessage}
              onClick={onClickCardAction}
              savedObject={null}
            />
          );
        },
      },
    ];

    return (
      <>
        <h2>Topics at a Glance</h2>
        <Table
          rowKey={"artifact_sort_key"}
          showHeader={true}
          pagination={false}
          columns={tableColumns}
          dataSource={messageArtifacts?.summary_bullets || []}
          style={{ marginBottom: 25 }}
        />
      </>
    );
  };

  return getJsx();
};

export default MessageSummaryBullets;
