import React, { useEffect } from "react";
import { Col, Row, Space, Typography } from "antd";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import useListQueryRollup from "../artifactRollups/hooks/useListQueryRollup";
import FilesPlusRollupUserEducationModal from "../artifactRollups/filesPlus/filesPlusRollupUserEducationModal";
import useSearchParamPagination from "../../hooks/utils/useSearchParamPagination";
import SortSelect from "../common/commonTldr/sortSelect";
import SpaceBetweenRow from "../common/generic/spaceBetweenRow";
import StandardPagination from "../common/generic/standardPagination";
import FilesPlusRollupInner from "../artifactRollups/filesPlus/filesPlusRollupInner";

const { Text } = Typography;

const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

const LinkSuggestions = () => {
  const { setBreadcrumbs, clearBreadcrumbExtra } = useTldrPageContext();

  const {
    currentPage,
    orderBy,
    changePage,
    getSearchParam,
    changeOrderOrFilterAndNavigate,
  } = useSearchParamPagination({
    order: "message_date",
    show_hidden: "false",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Link Suggestions",
      },
    ]);
    clearBreadcrumbExtra();
  }, [setBreadcrumbs, clearBreadcrumbExtra]);

  const { data: listQueryData } = useListQueryRollup(
    "tldr",
    null,
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    orderBy,
    getSearchParam("show_hidden"),
  );

  const getSortDropdownSection = () => {
    return (
      <Row justify={"start"} gutter={[15, 15]} style={{ marginBottom: 15 }}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Space
            size={"small"}
            style={{ width: "100%" }}
            direction={"vertical"}
          >
            <div>
              <Text strong={true}>Sort</Text>
            </div>
            <SortSelect
              orderByValue={orderBy}
              onChange={(selectedItem) => {
                changeOrderOrFilterAndNavigate("order", selectedItem);
              }}
              selectStyle={{ width: "100%" }}
            />
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <FilesPlusRollupUserEducationModal forced={true} />
      {getSortDropdownSection()}
      <SpaceBetweenRow
        leftSide={null}
        rightSide={
          <StandardPagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={changePage}
            total={listQueryData?.found || 0}
          />
        }
      />

      <FilesPlusRollupInner
        listQueryData={listQueryData}
        orderBy={orderBy}
        cardProperties={{
          link_image_rollup: {
            showLink:
              getSearchParam("native_tag") === "link" ||
              getSearchParam("native_tag") === "tldr",
            showImage: getSearchParam("native_tag") === "image",
          },
        }}
      />

      <SpaceBetweenRow
        leftSide={null}
        rightSide={
          <StandardPagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={changePage}
            total={listQueryData?.found || 0}
          />
        }
      />
    </>
  );
};

export default LinkSuggestions;
