import React from "react";
import {
  ALL_CLASSES_BY_CLASS_ID,
  getObjectTitle,
  getOnClickAction,
} from "../actionMappers/classesToActions";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { Actions } from "../actionMappers/actions";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const ObjectCardActionsDropdown = ({
  ecObject,
  appContext,
  onClick,
  savedObject,
  cardProperties,
}) => {
  const getOrderedActionsForObjectClassAndAppContext = () => {
    if (ecObject) {
      const clazz = ALL_CLASSES_BY_CLASS_ID[ecObject.class_id];
      const classActions = clazz.getActions();
      const appContextActions = appContext.getActions();
      const response = [];
      for (const i in classActions) {
        const classAction = classActions[i];
        if (appContextActions.includes(classAction)) {
          response.push(classAction);
        }
      }
      response.sort((a, b) => (a.order > b.order ? 1 : -1));
      return response;
    } else {
      return [];
    }
  };

  const includeActionInDropdownList = (action) => {
    if (["edit", "delete"].includes(action.actionType)) {
      return false;
    }

    if (
      action.name === "createClippingAction" &&
      appContext?.showCopyToWorkbenchButton === true
    ) {
      return false;
    }

    return true;
  };

  const getCreateButtonItems = () => {
    const relevantActions = getOrderedActionsForObjectClassAndAppContext();
    const items = [];
    for (const i in relevantActions) {
      const relevantAction = relevantActions[i];
      if (includeActionInDropdownList(relevantAction)) {
        const Icon = relevantAction.icon;
        items.push({
          key: relevantAction.name,
          icon: <Icon />,
          danger: relevantAction.name.toLowerCase().search("delete") !== -1,
          label: (
            <a
              href={"#"}
              onClick={(e) => {
                e.preventDefault();
                const clickHandler = getOnClickAction(
                  onClick,
                  ecObject,
                  savedObject,
                  appContext,
                  cardProperties,
                  relevantAction,
                );
                clickHandler();
              }}
            >
              {relevantAction.tooltipTitle}
            </a>
          ),
        });
      }
    }

    return {
      items: items,
    };
  };

  const getSendToWorkbenchButton = () => {
    const objectType = getObjectTitle(ecObject).toLowerCase();
    return (
      <>
        {appContext?.showCopyToWorkbenchButton && (
          <Tooltip title={`Copy ${objectType} to workbench`}>
            <Button
              type={"primary"}
              onClick={() => {
                onClick(
                  {
                    classAction: Actions.createClippingAction.name,
                    sourceInfo:
                      ALL_CLASSES_BY_CLASS_ID[ecObject.class_id].getSourceInfo(
                        ecObject,
                      ),
                    ecObject: ecObject,
                    savedObject: savedObject,
                  },
                  ecObject,
                );
              }}
            >
              Copy to Workbench
            </Button>
          </Tooltip>
        )}
      </>
    );
  };

  const getCreateButton = () => {
    const objectType = getObjectTitle(ecObject).toLowerCase();
    return (
      <Tooltip title={`Create actionable artifact from ${objectType}...`}>
        <Dropdown
          menu={getCreateButtonItems()}
          arrow={true}
          trigger={["click"]}
        >
          <Button
            type={"primary"}
            ghost={true}
            icon={<IosShareOutlinedIcon sx={{ fontSize: 20 }} />}
          />
        </Dropdown>
      </Tooltip>
    );
  };

  const getEditButton = () => {
    if (appContext?.showEditButton === true) {
      return (
        <Button
          type={"primary"}
          ghost={true}
          icon={<EditOutlinedIcon sx={{ fontSize: 20 }} />}
        />
      );
    }
    return <></>;
  };

  const getDeleteButton = () => {
    if (appContext?.showDeleteButton !== true) {
      return <></>;
    }

    let toolTipTitle = "";
    let relevantAction;
    if (savedObject?.class_id === "clipping") {
      toolTipTitle = "Remove from workbench";
      relevantAction = Actions.deleteClippingAction;
    } else if (savedObject?.class_id === "tile") {
      toolTipTitle = "Remove from board";
      relevantAction = Actions.deleteTileBoardAssociationAction;
    }

    const clickHandler = getOnClickAction(
      onClick,
      ecObject,
      savedObject,
      appContext,
      cardProperties,
      relevantAction,
    );

    return (
      <Tooltip title={toolTipTitle}>
        <Button
          type={"primary"}
          icon={<DeleteOutlineIcon sx={{ fontSize: 20 }} />}
          ghost={true}
          danger={true}
          onClick={clickHandler}
        />
      </Tooltip>
    );
  };

  const getJsx = () => {
    const menuItems = getCreateButtonItems();
    if (menuItems.items.length === 0) {
      return;
    }

    return (
      <Space>
        {getSendToWorkbenchButton()}
        {getCreateButton()}
        {getEditButton()}
        {getDeleteButton()}
      </Space>
    );
  };

  return getJsx();
};

export default ObjectCardActionsDropdown;
