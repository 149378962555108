import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "../../framework/apiClient";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_QUERIES } from "../../../serviceUrls/serviceUrlsEmailCollector";
import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";

const useUpdateEcQuery = (onSubmit, onSuccess, onError) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  return useMutation({
    mutationFn: async (data) => {
      const { tenantId, queryId, ...restOfData } = data;
      const token = await getAccessTokenSilently();
      let endpoint = replacePlaceholdersInTemplate(EC_SERVICE_QUERIES, {
        queryId,
      });
      endpoint = `/tenants/${tenantId}${endpoint}`;

      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
        endpoint,
        token,
      ).patchWithoutId(restOfData);
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useUpdateEcQuery;
