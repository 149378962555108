import useQueryGenericGet from "../../framework/useQueryGenericGet";
import { O_EC_QUERIES_FOR_CURRENT_USER } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";

const useListEcQueriesForCurrentUser = () => {
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_EC_QUERIES_FOR_CURRENT_USER,
    true,
    [],
    {},
    true,
  );
};

export default useListEcQueriesForCurrentUser;
