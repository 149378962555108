import React from "react";
import useStateForMagicCalendar from "../../magicCalendar/hooks/useStateForMagicCalendar";
import MagicCalendarInner from "../../magicCalendar/parts/magicCalendarInner";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Space } from "antd";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import { useNavigate } from "react-router-dom";

const HomeEventsCard = () => {
  const { familyId } = useTldrPageContext();
  const navigate = useNavigate();

  const {
    zeroResults,
    eventsAndStartDates,
    eventsList,
    showHidden,
    handleUpdateHiddenStatus,
  } = useStateForMagicCalendar();

  return (
    <>
      <Space>
        <h1>Event Suggestions</h1>
        <ArrowForwardIcon
          sx={{ fontSize: 24, color: "#168dff", cursor: "pointer" }}
          onClick={() => navigate(`/families/${familyId}/event-suggestions`)}
        />
      </Space>
      <div>
        <MagicCalendarInner
          zeroResults={zeroResults}
          eventsAndStartDates={eventsAndStartDates}
          eventsList={eventsList}
          showHidden={showHidden}
          handleUpdateHiddenStatus={handleUpdateHiddenStatus}
        />
      </div>
    </>
  );
};

export default HomeEventsCard;
